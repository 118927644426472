import React from 'react'
import { Twitter, Linkedin, Instagram } from "react-feather" 
import { OutboundLink } from "gatsby-plugin-google-gtag"

export const SocialButtons = () => (
  <div className="bio-social-icons">
    <OutboundLink href="https://www.linkedin.com/in/lukaszbyjos">
      <Linkedin strokeWidth={0.75} size={18}/>
    </OutboundLink>
    <OutboundLink href="https://twitter.com/lukaszbyjos">
      <Twitter strokeWidth={0.75} size={18}/>
    </OutboundLink>
    <OutboundLink href="https://www.instagram.com/lukaszbyjos">
      <Instagram strokeWidth={0.75} size={18}/>
    </OutboundLink>
  </div>
)
